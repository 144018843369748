// palette for CandyLand Casino
//$bg-color: #279ec9;
$bg-color: #400036;
$text-color: #f6e5f1;
$primary: #fa22a0;
$success: #23FA62;
$info: #4482d4;
$danger: #e42f5f;




$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

$gray-700: mix($bg-color, #000, 80%); //bs-border-color

.modal {
  .form-control, select {
    background-color: mix($bg-color, #000, 90%) !important;
  }
  a {
    color:  mix($primary, #000, 90%) !important;
    &:hover {
      color: $primary !important;
    }
  }
}

.text-darker {
  //color: #{$text-color} !important;
  color: mix($text-color, #000, 95%) !important;
}
.accordion {
  --bs-accordion-active-bg: transparent !important;
}




//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

#rivalHeaderNavAndLogoSection img {
  max-height: 60px;
  margin-bottom: -4px;
}


.sticky-sidebar {
  .nav-link:hover {
    //color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }

  .dropdown-item:active {
    background-color: $primary !important;
  }
}



#promos {
  img {
    width: 150% !important;
  }
}

.btn-primary {
  --bs-btn-color: $text-color !important;
  --bs-btn-hover-color: $text-color !important;
  --bs-btn-active-color: $text-color !important;
}

.btn-outline-primary {
  --bs-btn-color: $text-color !important;
  --bs-btn-border-color: #{ $text-color } !important;
  --bs-btn-hover-color: $text-color !important;
  --bs-btn-active-color: $text-color !important;
}

.btn-outline-secondary {
  --bs-btn-color: #{ $text-color }  !important;
  --bs-btn-hover-color: #{ $text-color } !important;
  --bs-btn-active-color: #{ $text-color }  !important;
}

.btn-secondary {
  --bs-btn-color: #{ $bg-color } !important;
  --bs-btn-hover-color: #{ $text-color } !important;
  --bs-btn-active-color: #{ $text-color } !important;
  --bs-btn-disabled-color: #{ $bg-color } !important;
}

